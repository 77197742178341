<template>
    <div class="WmaCostPrice" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never" style="margin-top: 8px">
            <el-form @submit.native.prevent :inline="true" v-model="form" size="small">
                <el-row>
                    <ef-dept-type-and-dept-select
                        @selectDeptType="setDeptType"
                        @selectDept="setDept"
                        :componentLoadingCompleteFlag.sync="pageComponentLoadingCompleteFlag"
                    />
                    <el-col :span="8">
                        <ef-review-status v-model="form.reviewStatus" />
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <ef-start-date v-model="form.startTime" />
                    </el-col>
                    <el-col :span="8">
                        <ef-end-date v-model="form.endTime" />
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px; text-align: left">
            <el-button
                type="primary"
                @click="searchHandleQuery"
                size="small"
                v-if="hasPrivilege('menu.goods.wma.costPrice.open')"
                >查询
            </el-button>
            <el-button
                type="primary"
                @click="handleCreate"
                size="small"
                v-if="hasPrivilege('menu.goods.wma.costPrice.create')"
                >新建
            </el-button>
            <el-button
                type="primary"
                @click="showReviewDialog"
                size="small"
                v-if="hasPrivilege('menu.goods.wma.costPrice.review')"
                >审核
            </el-button>
            <el-button
                type="primary"
                @click="handleExport"
                size="small"
                v-if="hasPrivilege('menu.goods.wma.costPrice.export')"
                >导出
            </el-button>
            <span style="float: right">
                <el-button size="mini" @click="showCheckTableShowColumnDialog">表头</el-button>
                <el-button size="mini" @click="handlePrint">打印</el-button>
            </span>
        </el-card>
        <check-table-show-column-dialog ref="checkTableShowColumnDialog" @checkTableShowColumn="setShowTableColumns" />
        <el-card shadow="never" style="margin-top: 8px">
            <el-table
                border
                stripe
                ref="table"
                style="width: 100%"
                :data="tableData"
                size="mini"
                id="printMe"
                :highlight-current-row="true"
                max-height="460"
                @selection-change="handleSelectionChange"
            >
                <el-table-column width="40" type="selection" />
                <el-table-column label="序号" type="index" width="60" align="center" fixed="left" />
                <el-table-column prop="code" label="编码" width="180" v-if="showColumn('code')" />
                <el-table-column prop="deptName" label="机构名称" width="180" v-if="showColumn('deptName')" />
                <el-table-column prop="goodsName" label="商品名称" width="180" v-if="showColumn('goodsName')" />
                <el-table-column
                    prop="reviewStatus"
                    label="状态"
                    width="80"
                    v-if="showColumn('reviewStatus')"
                    key="reviewStatus"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.reviewStatus | reviewStatus }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="oldStockCostPrice"
                    label="旧的移动加权成本单价"
                    width="180"
                    v-if="showColumn('oldStockCostPrice')"
                />
                <el-table-column
                    prop="newStockCostPrice"
                    label="新的移动加权成本单价"
                    width="180"
                    v-if="showColumn('newStockCostPrice')"
                />
                <el-table-column prop="creator" label="创建人" width="120" v-if="showColumn('creator')" />
                <el-table-column prop="createTime" label="创建时间" width="150" v-if="showColumn('createTime')" />
                <el-table-column
                    label="操作"
                    min-width="275"
                    header-align="center"
                    v-if="showColumn('operate')"
                    prop="operate"
                    key="operate"
                >
                    <template slot-scope="scope">
                        <el-button
                            size="mini"
                            @click="rowDetail(scope.row)"
                            v-if="hasPrivilege('menu.goods.wma.costPrice.open')"
                            >查看
                        </el-button>
                        <el-button
                            size="mini"
                            type="warning"
                            @click="rowEdit(scope.row)"
                            v-if="hasPrivilege('menu.goods.wma.costPrice.edit')"
                            :disabled="scope.row.reviewStatus == 2"
                            >编辑
                        </el-button>
                        <el-button
                            size="mini"
                            type="danger"
                            @click="handleDelete(scope.row)"
                            v-if="hasPrivilege('menu.goods.wma.costPrice.delete')"
                            >删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <ef-pagination
                :total="total"
                :default-limit="form.limit"
                @pageChange="pageChange"
                :current-page="form.page"
            />
        </el-card>
        <ef-review-biz ref="review" :baseUrl="baseUrl" @completed="handleQuery" />
    </div>
</template>

<script>
import Util from '@/js/Util';
import UrlUtils from 'js/UrlUtils';
import EfDeptTypeAndDeptSelect from 'components/EfDeptTypeAndDeptSelect';
import EfPagination from 'components/EfPagination';
import EfReviewBiz from 'components/EfReviewBiz';
import EfReviewStatus from 'components/EfReviewStatus';
import EfStartDate from 'components/EfStartDate';
import EfEndDate from 'components/EfEndDate';
import CheckTableShowColumn from 'components/mixins/CheckTableShowColumn';
import CheckTableShowColumnDialog from 'components/CheckTableShowColumnDialog';

export default {
    name: 'WmaCostPrice',
    components: {
        EfDeptTypeAndDeptSelect,
        CheckTableShowColumnDialog,
        EfPagination,
        EfReviewBiz,
        EfReviewStatus,
        EfStartDate,
        EfEndDate,
    },
    mixins: [CheckTableShowColumn],
    data() {
        return {
            baseUrl: this.$efApi.wmaCostPriceChangeApi.baseUrl,
            form: {
                deptType: null,
                deptCode: null,
                search: '',
                reviewStatus: null,
                startTime: '',
                endTime: '',
                page: 1,
                limit: 100,
            },
            tableData: [],
            total: 0,
            selectedBizArr: [],
            pageComponentLoadingCompleteFlag: false,
        };
    },
    mounted() {
        Util.setIntervalAndTimeout(
            () => {
                return this.pageComponentLoadingCompleteFlag;
            },
            () => {
                return true;
            },
            () => {
                this.handleQuery();
            },
            3000
        );
    },
    activated() {
        this.executeQueryIfNeedReload(this.handleQuery);
    },
    methods: {
        handlePrint() {
            this.printHTML('printMe', this.$route.meta.title);
        },
        searchHandleQuery() {
            this.form.page = 1;
            this.handleQuery();
        },
        async handleQuery() {
            if (!this.pageComponentLoadingCompleteFlag) {
                //只要有一个false 就代表有组件未加载完成
                return;
            }
            const rst = await this.$efApi.wmaCostPriceChangeApi.page(this.form);
            this.tableData = rst.data;
            this.total = rst.count;
        },
        handleCreate() {
            Util.nameJump(this, 'menu.goods.wma.costPrice.create', ['商品管理', '商品移动加权成本调价', '新建调价']);
        },
        handleExport() {
            const codes = (this.$refs.table.selection || []).map((e) => e.code);

            UrlUtils.Export(this, '商品移动加权成本调价', '/goods/wma/costPrice/export', this.form, codes);
        },
        rowDetail(row) {
            Util.nameJump(this, 'menu.goods.wma.costPrice.detail', ['商品管理', '商品移动加权成本调价', '价格详情'], {
                code: row.code,
            });
        },
        rowEdit(row) {
            if (row.reviewStatus === 0) {
                Util.nameJump(this, 'menu.goods.wma.costPrice.edit', ['商品管理', '商品移动加权成本调价', '编辑'], {
                    form: row,
                    code: row.code,
                });
            } else {
                this.$message.error('非待审核状态不可编辑');
            }
        },
        async handleDelete(row) {
            if (row.reviewStatus !== 0) {
                this.$message.error('非待审核状态不可删除');
                return false;
            }
            await this.$efApi.wmaCostPriceChangeApi.deleteBiz(row.code);
            this.handleQuery();
        },
        pageChange(page, limit) {
            this.form.page = page;
            this.form.limit = limit;
            this.handleQuery();
        },
        setDept(deptCode, deptName) {
            this.form.deptCode = deptCode;
            this.form.deptName = deptName;
        },
        setDeptType(deptType) {
            this.form.deptType = deptType;
        },
        showReviewDialog() {
            this.$refs.review.open(this.selectedBizArr);
        },
        handleSelectionChange(selections) {
            this.selectedBizArr = selections.map((e) => {
                return { code: e.code, reviewStatus: e.reviewStatus };
            });
        },
    },
};
</script>

<style scoped>
.WmaCostPrice .el-form-item {
    margin-bottom: 0;
}
</style>
